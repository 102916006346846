<template lang="pug">
  include ../plugins/pug/btn

  div.contactspage.second__page
    div.custom__loader
    PageHeaderBlock(
      :header-img="WallContact"
      :header-title="'Contacts'"
      :header-content="`We're always here to help! Whether you have a question about our services, need assistance with a current project, or want to discuss a custom solution tailored to your business needs, our dedicated team is just a phone call or email away.`"
    )

    section.section
      div.container
        div.contact__body
          div.dialog__window.contact__info
            div.dialog__window-top
              span
            div.dialog__window-content
              a(
                href="https://goo.gl/maps/LdMaZiisQGwFqkEW8"
                target="_blank"
              ).contact__map.img__wrapp.hover__scale
                img(
                  :src="ContactMap"
                  alt="map"
                )

              div.contact__info-body
                div.contact__info-item
                  h3.contact__info-title.h3.fw-700 Email
                  ul.contact__info-links
                    li
                      a(href="mailto:hr@disoft.dev") hr@disoft.dev
                    li
                      a(href="mailto:mail@disoft.dev") mail@disoft.dev

                div.contact__info-item
                  h3.contact__info-title.h3.fw-700 Phone number
                  ul.contact__info-links
                    li
                      a(href="tel:+16102458044") +1 (610) 245 80 44

                div.contact__info-item
                  h3.contact__info-title.h3.fw-700 Main office
                  ul.contact__info-links.contact__office
                    li
                      a(
                        href="https://goo.gl/maps/LdMaZiisQGwFqkEW8"
                        target="_blank"
                      ) 802 Patterson ave. Staten Island, New York 10306. USA

              SocialBlock

          FeedbackForm
</template>

<script>
// images
import WallContact from '@/assets/icons/wall-contact.svg'
import ContactMap from '@/assets/img/contact-map.svg'
export default {
  name: 'ContactsPage',
  components: {
    PageHeaderBlock: () => import('@/components/PageHeaderBlock'),
    FeedbackForm: () => import('@/components/FeedbackForm'),
    SocialBlock: () => import('@/components/SocialBlock')
  },
  data () {
    return {
      WallContact,
      ContactMap
    }
  }
}
</script>
